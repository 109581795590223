#column1 {
    float: left;
    width: 80%;
}

#column2 {
    float: right;
    width: 20%;
}

.column {
    float: left;
    width: 50%;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}